import React from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from "./pages/PgHome";
import PageNotFound from "./pages/PgPageNotFound";
// import RegisterPlan from "./pages/PgRegisterPlan";
import SigninAccount from "./pages/PgUserSignin";
import RegisterAccount from "./pages/PgUserRegister";
// import ContactUs from "./pages/PgContactUs";
// import TestSite from './pages/PgtestSite';

export const AppInit = () =>{

    return (
            <div>
                
                <BrowserRouter>
                    <Routes>
                        <Route index element={<Home />}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/home.html" element={<Home/>}/>
                        <Route path="/home.php" element={<Home/>}/>

                        <Route path="/index" element={<Home/>}/>
                        <Route path="/index.html" element={<Home/>}/>
                        <Route path="/index.php" element={<Home/>}/>

                        {/* <Route path="/registerPlan" element={<RegisterPlan/>}/> */}
                            
                        {/* <Route path="/ContactUs" element={<ContactUs/>}/>
                        <Route path="/ContactUs.html" element={<ContactUs/>}/>
                        <Route path="/ContactUs.php" element={<ContactUs/>}/> */}
                            
                        <Route path="/Signin" element={<SigninAccount/>}/>
                        <Route path="/Signin.html" element={<SigninAccount/>}/>
                        <Route path="/Signin.php" element={<SigninAccount/>}/>

                        <Route path="/Register" element={<RegisterAccount/>}/>
                        <Route path="/Register.html" element={<RegisterAccount/>}/>
                        <Route path="/Register.php" element={<RegisterAccount/>}/>

                        {/* <Route path="/TestSite" element={<TestSite />}/> */}

                        <Route path="/*" element={<PageNotFound/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
    );
};

export default AppInit;