import React from 'react';
import { Row, Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import logo_img from '../img/logo.jpg'

export const Footers = () => {
  return (
    <footer>
      <Container fluid>
        <Row className='bg-primary text-white p-4'>
          <Col className="mx-5">
            <Stack>
              <Image
                src={logo_img}
                alt="NVARDA"
                rounded
                width={150}
                height={150}
                />

              <h2>DTR Studios</h2>
              <p>E-Talent site</p>
            </Stack>
          </Col>
          {/* <Col>
            <Nav className='flex-column fs-5'>
              Useful Links
              <NavLink href="#" className='text-white'>Home</NavLink>
              <NavLink href="#" className='text-white'>Customer support</NavLink>
              <NavLink href="#" className='text-white'>Info@nvarda.co.uk</NavLink>
            </Nav>
          </Col> */}
          <Col>
            <h4>Useful links</h4>
            <p>Home</p>
            <p>Careers</p>
            <p>Customer service</p>
          </Col>
          <Col>
            <h4>Follow us</h4>
            <p>cherryainc@gmail.com</p>
            <p><a href="https://www.instagram.com/" className='text-white text-decoration-none'>Instagram: DTR studios</a></p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footers;