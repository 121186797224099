// src/useInstallPrompt.js
import { useState, useEffect } from 'react';

const useInstallPrompt = () => {
    const [installPromptEvent, setInstallPromptEvent] = useState(null);
    const [isInstalled, setIsInstalled] = useState(false); // To track if the app is installed

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault(); // Prevent the default install prompt
            setInstallPromptEvent(event); // Save the event to trigger it later
        };

        const checkIfInstalled = () => {
            // Check if the app is already installed
            if (window.matchMedia('(display-mode: standalone)').matches) {
                setIsInstalled(true);
            }
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        checkIfInstalled(); // Initial check

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const promptInstall = () => {
        if (installPromptEvent) {
            installPromptEvent.prompt(); // Show the install prompt
            setInstallPromptEvent(null); // Clear the saved event
        }
    };

    return { promptInstall, isInstalled };
};

export default useInstallPrompt;
