import Footers from "../components/Footers";
import Headers from "../components/Headers";

export const PageNotFound=()=>{
    return(
        <div>
            <Headers/>
            <div class="text-center">
                <h1 className="display-1">Page not Found!</h1>
                <h2>(Website still under development)</h2>
                <p>It looks like the page you are trying to find doesnt exist! <br/> Check URL: {window.location.href} <br/> Click here to go <a href="/">home</a></p>
            </div>
            <Footers/>
        </div>
    );
}

export default PageNotFound;