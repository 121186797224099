import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import useInstallPrompt from '../installPrompt/useInstallPrompt';

export const Headers = () => {
    const { promptInstall, isInstalled } = useInstallPrompt(); // Destructure to get install status

    const handleInstallClick = () => {
        promptInstall(); // Call the install function when clicked
    };

    // Check if the user is on Android (uncomment if needed)
    // const isAndroid = /Android/i.test(navigator.userAgent);

    return (
        <Navbar expand="lg" className="bg-body-tertiary sticky-top">
            <Container fluid>
                <Navbar.Brand href="/">DTR studios</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '200px' }} navbarScroll>
                        <Nav.Link href="/">Home</Nav.Link>
                        {/* <Nav.Link href="https://www.dashboard.hosting.nvarda.co.uk">Dashboard</Nav.Link> */}
                        <Nav.Link href="/Signin">Signin / <a href='/Register' style={{ color: 'inherit', textDecoration: 'none' }}>Register</a></Nav.Link>
                        <Nav.Link href="/TestSite">Test site</Nav.Link>
                        <Nav.Link href="/Community">Community</Nav.Link>
                        <Nav.Link href="/Documents">Docs</Nav.Link>
                        <Nav.Link href="/ContactUs">Contact us</Nav.Link>
                        
                        {/* Conditionally render the Install button */}
                        {!isInstalled && (
                        // {!isInstalled && !isAndroid && (
                            <Nav.Link onClick={handleInstallClick} style={{ cursor: 'pointer' }}>
                                Install
                            </Nav.Link>
                        )}
                    </Nav>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Search"
                            className="me-2"
                            aria-label="Search"
                        />
                        <Button variant="outline-primary">Search</Button>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Headers;
