export const loginUser = async (emailOrUsername, password) => {
    // console.log("Details: ", emailOrUsername, password);
    
    try {
        console.log("[loginUser] Initiating fetch");
        const response = await fetch('https://www.api.hosting.nvarda.co.uk/api/authenticate/user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                emailOrUsername,
                password,
            }),
        });

        console.log("[loginUser] Fetch complete");
        
        // Check if the response is not ok (e.g., 4xx or 5xx response)
        if (!response.ok) {
            const errorResponse = await response.json(); // Parse the error response
            throw new Error(errorResponse.message || 'Login failed'); // Throw a custom error
        }

        const data = await response.json(); // Parse the successful response
        
        // Set cookies for LT and username, restricted to specific domains
        document.cookie = `LT=${data.LT}; Domain=.nvarda.co.uk; Path=/; Secure; SameSite=Lax`;
        // document.cookie = `LT=${data.LT}; Domain=.nvarda.co.uk; Path=/; Secure; HttpOnly; SameSite=Lax`;
        document.cookie = `username=${data.username}; Domain=.nvarda.co.uk; Path=/; Secure; SameSite=Lax`;

        // Redirect to dashboard
        window.location.href = 'https://www.dashboard.hosting.nvarda.co.uk'; // Redirect to the dashboard
        // window.location.href = 'https://www.hosting.nvarda.co.uk/TestSite'; // Redirect to the dashboard

        return { status: 'success', data }; // Return success status and data

    } catch (error) {
        console.error('Login failed:', error);
        return { status: 'error', message: error.message || 'Failed to login' };
    }
};

export const registerUser = async (userDetails) => {
    // console.log("User details: ",userDetails)
    try {
        const response = await fetch('https://www.api.hosting.nvarda.co.uk/api/db/addUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userDetails)
        });

        if (!response.ok) {
            // Handle server errors based on response status
            const errorData = await response.json();
            // console.log("Error start", errorData.message)
            throw new Error(errorData.message || 'Registration failed');
        }

        // console.log("[registerUser] response:", await response.json().response);

        return await response.json();
    } catch (error) {
        // console.error("Registration failed:", error);
        return { status: 'error', message: error.message || 'Failed to register' };
    }
};