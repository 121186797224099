import './App.css';
import AppInit from './rsc/AppInit';

function App() {
  return (
    <div>
      <AppInit />
    </div>
  );
}

export default App;
