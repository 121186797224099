import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';  
import Footers from '../components/Footers';
import Headers from '../components/Headers';
import { loginUser } from "../api/UserApi"; // Update import statement

const UserSignin = () => {
    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const loginHandler = async (e) => {
        e.preventDefault();

        if (!emailOrUsername || !password) {
            setErrorMessage('Please fill in both fields.');
            return;
        }

        try {
            console.log("[Sign in] Fetching response");
            const response = await loginUser(emailOrUsername, password); // Call the new loginUser function

            console.log("[Sign in] Response: ", response);

            if (response.status === 'error') {
                setErrorMessage(response.message); // Show error message from loginUser
            } else if (response.data.success) {
                setErrorMessage("If you haven't been redirected to dashboard.hosting.nvarda.co.uk, please copy and paste the address.");
            } else {
                setErrorMessage('Unexpected response from the server.');
            }
        } catch (error) {
            setErrorMessage('An error occurred while logging in. Please try again later.');
            console.error('Error:', error);
        }
    };

    return (
        <div>
            <Headers />
            <div className="container py-5">
                <form onSubmit={loginHandler}>
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}

                    <div className="form-outline mb-4">
                        <input
                            type="text"
                            id="loginName"
                            className="form-control"
                            required
                            value={emailOrUsername}
                            onChange={(e) => setEmailOrUsername(e.target.value)}
                        />
                        <label className="form-label" htmlFor="loginName">Email or Username</label>
                    </div>

                    <div className="form-outline mb-4">
                        <input
                            type="password"
                            id="loginPassword"
                            className="form-control"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="form-label" htmlFor="loginPassword">Password</label>
                    </div>

                    <button type="submit" className="btn btn-primary btn-block mb-4">
                        Sign in
                    </button>

                    <div className="text-center">
                        <p>Don't have an account? <Link to="/Register">Register here</Link></p>
                    </div>
                </form>
            </div>
            <Footers />
        </div>
    );
};

export default UserSignin;